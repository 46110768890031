import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { API_URL } from '../config';
import adjustContentUrls from '../utils/adjustContentUrls';
import FeaturedMedia from '../components/post/FeaturedMedia';
import PageTemplate from './PageTemplate';
import Fancybox from '../utils/Fancybox';
import SocialShare from '../components/global/SocialShare';
import { Helmet } from 'react-helmet';
import { stripHtml } from '../utils/stripHtml';
import { formatDate } from '../utils/formatDate';
import PPBannerComponent from '../components/global/PPBannerComponent';

export default function PostPage() {
  
  const { slug } = useParams();
  const [ pageContent, setPageContent ] = useState(null);
  const [ pageTitle, setPageTitle ] = useState(null);
  const [ pageExcerpt, setPageExcerpt ] = useState(null);
  const [ featuredImage, setFeaturedImage ] = useState(null);
  const [ featuredImageCaption, setFeaturedImageCaption ] = useState('');
  const [ postCategory, setPostCategory ] = useState('');
  const [ postAuthor, setPostAuthor ] = useState('');
  const [ postDate, setPostDate ] = useState('');
  const location = useLocation();
  const currentUrl = window.location.origin + location.pathname;

  useEffect(() => {
    setFeaturedImage(null);
    setFeaturedImageCaption(null);
    setPostDate(null);

    fetch(`${API_URL}/posts?slug=${slug}`)
      .then(response => response.json())
      .then(data => {
        const adjustedContent = adjustContentUrls(data[0].content.rendered);
        const strippedExcerpt = stripHtml(data[0].excerpt.rendered);
        setPageContent(adjustedContent);
        setPageTitle(data[0].title.rendered);
        setPageExcerpt(strippedExcerpt);

        // Check if the ACF field for external source is filled
        const author = data[0].acf.external_source ? data[0].acf.external_source : data[0].author_name;
        setPostAuthor(author);

        setPostDate(formatDate(data[0].date));
        
        if (data[0].featured_media) {
          fetch(`${API_URL}/media/${data[0].featured_media}`)
              .then(response => response.json())
              .then(mediaData => {
                  const imageUrl = mediaData.media_details.sizes.large.source_url;
                  const imageCaptions = mediaData.alt_text;
                  setFeaturedImage(imageUrl);
                  setFeaturedImageCaption(imageCaptions);
              });
        }

        if (data[0].categories) {
          fetch(`${API_URL}/categories/${data[0].categories}`)
            .then(response => response.json())
            .then(categories => {
              const postCategory = categories.name;
              setPostCategory(postCategory);
            })
        }

      })
      .catch(() => setPageContent('<p>Puslapis nerastas.</p>'));
  }, [slug]);

  return (
    <PageTemplate>

      <Helmet>
          <title>{pageTitle ? `${pageTitle} - Gyvenu Kaune` : 'Gyvenu Kaune'}</title>
          <meta name="description" content={pageExcerpt}></meta>
          <meta property="og:type" content="article" />
          <meta property="og:locale" content="lt_LT" />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageExcerpt} />
          <meta property="og:image" content={featuredImage} />
          <meta property="og:url" content={window.location.href} />
      </Helmet>

      <h1>{pageTitle}</h1>
      <span className="innerDetails taxonomy">
        {postCategory}<br/>{postAuthor} • {postDate}
      </span>
      <FeaturedMedia 
        imageUrl={featuredImage} 
        altText={featuredImageCaption}
        caption={featuredImageCaption}
      />
      <Fancybox className="content-wrapper">
        <div className="renderedContent" dangerouslySetInnerHTML={{ __html: pageContent }} />
      </Fancybox>
      <SocialShare currentUrl={currentUrl} title={pageTitle} />
      <PPBannerComponent />
    </PageTemplate>
  );
}
