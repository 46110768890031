import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function PageTemplate({ children }) {

  const location = useLocation();

  useEffect(() => {
    document.body.scrollIntoView({ behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <div className="page-container">
      <main>{children}</main>
    </div>
  );
}
