import React from 'react';
import { FaCamera, FaVideo } from 'react-icons/fa';

export default function FeaturedMedia({ imageUrl, altText, postFormat, caption }) {
    return (
        <>
            <figure className="featuredMedia">
                <img src={imageUrl} alt={altText} />
                {postFormat === 'gallery' && <span className="icon camera-icon"><FaCamera /></span>}
                {postFormat === 'video' && <span className="icon video-icon"><FaVideo /></span>}
            </figure>
            {caption && <figcaption>{caption}</figcaption>}
        </>
    );
}
