import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, AUTH_HEADER } from '../../config';
import { FooterMenu } from './MainMenuComponent';
import authorImage from '../../assets/images/brigita-sabaliauskaite.jpg';

function Footer() {

    const [siteTitle, setSiteTitle] = useState('');

    useEffect(() => {
        fetch(`${API_URL}/settings`, {
          headers: {
              'Authorization': AUTH_HEADER
          }
        })
        .then(response => response.json())
        .then(data => {
          if (data.title) {
            setSiteTitle(data.title);
            }
        });
      }, []);

    return (
        <footer>
            <Link className="branding" to={`/`} alt={siteTitle} title={siteTitle}>{siteTitle}</Link>
            <div className='inner'>
                <section className='contacts'>
                    <div className='authorBox'>
                        <div className='authorImage'>
                            <img src={authorImage} alt="Author's Name" />
                        </div>
                        <p><strong>Brigita Sabaliauskaitė</strong><br/>Vyr. redaktorė ir žurnalistė<br/><Link to="mailto:info@prastipopieriai.lt" alt="info@prastipopieriai.lt" title="info@prastipopieriai.lt">info@prastipopieriai.lt</Link></p>
                    </div>
                </section>
                <section className='footerNavigation'>
                    <FooterMenu />
                </section>
            </div>
            <div className='copy'>
                <span className='small'>
                    Visos teisės saugomos © Gyvenu Kaune. Kopijuoti, dauginti, platinti galima tik gavus raštišką „Gyvenu Kaune” sutikimą.
                </span>
            </div>
        </footer>
    );
}

export default Footer;