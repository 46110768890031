import './App.css';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { API_URL, AUTH_HEADER } from './config';
import { Helmet } from 'react-helmet';
import useScrollToTop from './utils/useScrollToTop';
import ReactGA from "react-ga4";

import { QueryClient, QueryClientProvider } from 'react-query';

import Header from './components/global/HeaderComponent';
import Footer from './components/global/FooterComponent';

import HomePage from './pages/HomePage';
import DynamicPage from './pages/DynamicPage';
import ArchivePage from './pages/ArchivePage';
import PostPage from './pages/PostPage';

const queryClient = new QueryClient({
  defaultOptions: {
      queries: {
          staleTime: 1000 * 60 * 10, // 10 minutes
      },
  },
});

function RouterContent() {
  useScrollToTop();

  const location = useLocation();

  // Initialize GA and track pages
  useEffect(() => {
    ReactGA.initialize('G-MJ86X07GSX');
  }, [location]);

  const [siteTitle, setSiteTitle] = useState('');

  useEffect(() => {
    fetch(`${API_URL}/settings`, {
      headers: {
          'Authorization': AUTH_HEADER
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.title) {
        setSiteTitle(data.title);
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>

      <div className="App">
        <Header />

        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/category/:category" element={<ArchivePage />} />
            <Route path="/:slug" element={<DynamicPage />} />
            <Route path="/:year/:month/:day/:category/:slug" element={<PostPage />} />
        </Routes>

        <Footer />
      </div>
    </>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <RouterContent />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
