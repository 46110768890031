export function formatDate(inputDate) {
    const date = new Date(inputDate);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}

export function formatDateForURL(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');  // JavaScript months are 0-indexed.
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
}