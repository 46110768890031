import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate, formatDateForURL } from '../../utils/formatDate';
import FeaturedMedia from './FeaturedMedia';

export default function PostComponent({ post, featured = false }) {

    const hasFeaturedMedia = post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0] && post._embedded['wp:featuredmedia'][0].media_details && post._embedded['wp:featuredmedia'][0].media_details.sizes && post._embedded['wp:featuredmedia'][0].media_details.sizes.large && post._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url;

    const imageUrl = hasFeaturedMedia ? post._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url : 'https://via.placeholder.com/600x400?text=No+Image';

    return (
        <div className={`post ${featured ? 'featured-post' : ''}`}>
            <Link to={`/${formatDateForURL(post.date)}/${post._embedded['wp:term'][0][0].slug}/${post.slug}`} key={post.id} title={post.title.rendered} alt={post.title.rendered}> 
                <FeaturedMedia 
                    imageUrl={imageUrl} 
                    altText={post.title.rendered}
                    postFormat={post.format}
                />
                <span className="taxonomy">{`${post._embedded['wp:term'][0][0].name}`} • {formatDate(post.date)}</span>
                <h2>{post.title.rendered}</h2>
            </Link>
        </div>
    );
}
