import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  const { className, children } = props;

  return <div ref={containerRef} className={className}>{children}</div>;
}

Fancybox.propTypes = {
  className: PropTypes.string, // Prop for additional class names
  delegate: PropTypes.string, // Prop for Fancybox delegate
  options: PropTypes.object, // Prop for Fancybox options
  children: PropTypes.node.isRequired, // Children components
};

export default Fancybox;
