import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";

export default function SocialShare({ currentUrl, title }) {
    return (
        <section className='socialShare-block'>
            <EmailShareButton
                url={currentUrl}
                subject={title}
                body="body"
            >
                <EmailIcon size={32} round={true} />
            </EmailShareButton>
            
            <FacebookShareButton
                url={currentUrl}
                quote={title}
            >
                <FacebookIcon size={32} round={true} />
            </FacebookShareButton>

            <LinkedinShareButton url={currentUrl}>
                <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
            
            <TelegramShareButton url={currentUrl}>
                <TelegramIcon size={32} round={true} />
            </TelegramShareButton>
            
            <TwitterShareButton
                url={currentUrl}
                title={title}
            >
                <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            
        </section>
    )
}
