import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function generatePageNumbers(currentPage, totalPages) {

    const maxPagesToShow = 3;
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        // Always add the first 2 and last 2 pages
        if (i <= 2 || i > totalPages - 2) {
            pageNumbers.push(i);
        }
        // Add pages around the current page
        else if (i >= currentPage - maxPagesToShow && i <= currentPage + maxPagesToShow) {
            pageNumbers.push(i);
        }
        // Replace with ellipsis (avoid consecutive ellipsis)
        else if (
            (i === currentPage - maxPagesToShow - 1 || i === currentPage + maxPagesToShow + 1) &&
            pageNumbers[pageNumbers.length - 1] !== '...'
        ) {
            pageNumbers.push('...');
        }
    }

    return pageNumbers;
}


export default function PaginationComponent({ currentPage, totalPages }) {
    useEffect(() => {
        document.body.scrollIntoView({ behavior: 'smooth' });
    }, [currentPage]);

    const pageNumbers = generatePageNumbers(currentPage, totalPages);

    return (
        <section className="pagination">
            {currentPage > 1 && <Link className="navButton" to={`/?page=${currentPage - 1}`}><FaChevronLeft />Ankstesnis</Link>}
            
            {pageNumbers.map((numberOrEllipsis) => (
                numberOrEllipsis === '...' ? (
                    <span key={numberOrEllipsis}>...</span>
                ) : numberOrEllipsis === currentPage ? (
                    <span key={numberOrEllipsis} className="activePage">
                        {numberOrEllipsis}
                    </span>
                ) : (
                    <Link 
                        key={numberOrEllipsis}
                        to={`/?page=${numberOrEllipsis}`}
                    >
                        {numberOrEllipsis}
                    </Link>
                )
            ))}
            
            {currentPage < totalPages && <Link className="navButton" to={`/?page=${currentPage + 1}`}>Kitas <FaChevronRight /></Link>}
        </section>
    );
}