import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { API_URL, DEFAULT_IMAGE_URL } from '../config';
import { TailSpin } from 'react-loader-spinner';
import PageTemplate from './PageTemplate';
import PostComponent from '../components/post/PostComponent';
import PaginationComponent from '../components/global/PaginationComponent';
import { Helmet } from 'react-helmet';
import PPBannerComponent from '../components/global/PPBannerComponent';

function HomePage() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const pageParam = parseInt(query.get('page')) || 1;

    const [page, setPage] = useState(pageParam);

    const fetchFeaturedPost = async () => {
        const res = await fetch(`${API_URL}/posts?_embed&featured=true&per_page=1`);
        const data = await res.json();
        return data[0];
    };

    const fetchPosts = async (page, featuredPostId) => {
        // const perPage = page === 1 ? 3 : 3;
        const perPage = 18;
        const res = await fetch(`${API_URL}/posts?_embed&per_page=${perPage}&page=${page}&exclude=${featuredPostId}`);
        const totalPages = Number(res.headers.get('X-WP-TotalPages'));
        const data = await res.json();
        return { data, totalPages };
    };

    const { data: featuredPost, isLoading: isLoadingFeaturedPost } = useQuery('featuredPost', fetchFeaturedPost, {
    });

    const { data: postData, isLoading: isLoadingPosts, isFetching } = useQuery(['posts', page, featuredPost?.id], () => fetchPosts(page, featuredPost?.id), {
        keepPreviousData: true,
        enabled: !!featuredPost,
    });

    const posts = postData?.data;
    const totalPages = postData?.totalPages;

    useEffect(() => {
        setPage(pageParam);
    }, [pageParam]);

    return (
        <PageTemplate>
            <Helmet>
                <title>Gyvenu Kaune</title>
                <meta name="description" content="Apie gyvenimą Kaune. Savo ir kitų. Savų ir svetimų. Gerai pažįstamų ir niekuomet iki šiol nesutiktų."></meta>
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="lt_LT" />
                <meta property="og:title" content="Gyvenu Kaune" />
                <meta property="og:description" content="Apie gyvenimą Kaune. Savo ir kitų. Savų ir svetimų. Gerai pažįstamų ir niekuomet iki šiol nesutiktų." />
                <meta property="og:image" content={DEFAULT_IMAGE_URL} />
                <meta property="og:url" content={window.location.href} />
            </Helmet>

            {isLoadingFeaturedPost || isLoadingPosts || isFetching ? (
                <TailSpin
                height="50"
                width="50"
                ariaLabel="Kraunasi..."
                color="black"
                />
            ) : (
                <>
                    <section className="posts-block">
                        {featuredPost && (
                            <PostComponent post={featuredPost} featured={true} />
                        )}

                        <PPBannerComponent />
                        
                        {posts && posts.map(post => (
                            <PostComponent post={post} key={post.id} />
                        ))}
                    </section>

                    {totalPages > 1 && <PaginationComponent currentPage={page} totalPages={totalPages} />}
                </>
            )}
        </PageTemplate>
    );
}

export default HomePage;