import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, AUTH_HEADER, BASE_URL } from '../../config';
import { BsX, BsList } from "react-icons/bs";

export default function MainMenu() {
  const [menuItems, setMenuItems] = useState([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/menu-items?menus=21`, {
        headers: {
            'Authorization': AUTH_HEADER
        }
    })
      .then(response => response.json())
      .then(data => {
        setMenuItems(data);
      });
  }, []);

  const toggleMenu = () => {
      setIsActive(!isActive);
  }

  const generateLink = (item) => {
    if (item.type === "category") {
      return `/category/${item.object_slug}`;
    } else {
      // Extract slug from the URL for other types
      return item.url.replace(BASE_URL, '');
    }
  };

  return (
    <nav id="primary">
      <div className={isActive ? 'overlay active' : 'overlay'}></div>
      <ul className={isActive ? 'active' : ''}>
        <BsX className="icon closeButton" onClick={toggleMenu} />
        {menuItems.map(item => {
          const slug = generateLink(item);
          return (
            <li key={item.id}>
              <Link to={`${slug}`} alt={item.title.rendered} title={item.title.rendered} onClick={toggleMenu}>
                {item.title.rendered}
              </Link>
            </li>
          );
        })}
      </ul>
      <BsList className="icon mobileMenu" onClick={toggleMenu} />
    </nav>
  );
}

export function FooterMenu() {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/menu-items?menus=51`, {
        headers: {
            'Authorization': AUTH_HEADER
        }
    })
      .then(response => response.json())
      .then(data => {
        setMenuItems(data);
      });
  }, []);

  const generateLink = (item) => {
    if (item.type === "category") {
      return `/category/${item.object_slug}`;
    } else {
      // Extract slug from the URL for other types
      return item.url.replace(BASE_URL, '');
    }
  };

  return (
    <nav id="footerNav">
      <ul>
        {menuItems.map(item => {
          const slug = generateLink(item);
          return (
            <li key={item.id}>
              <Link to={`${slug}`} alt={item.title.rendered} title={item.title.rendered}>
                {item.title.rendered}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
