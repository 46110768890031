import React from 'react';
import { useParams } from 'react-router-dom';
import PageTemplate from './PageTemplate';
import adjustContentUrls from '../utils/adjustContentUrls';
import { API_URL, DEFAULT_IMAGE_URL } from '../config';
import FeaturedMedia from '../components/post/FeaturedMedia';
import { Helmet } from 'react-helmet';
import { stripHtml } from '../utils/stripHtml';
import { useQuery } from 'react-query';

function DynamicPage() {
  const { slug } = useParams();

  const fetchPageData = async () => {
    const res = await fetch(`${API_URL}/pages?slug=${slug}`);
    const data = await res.json();
    return data[0];
  };

  const fetchMedia = async (featured_media) => {
    const res = await fetch(`${API_URL}/media/${featured_media}`);
    const mediaData = await res.json();
    return mediaData;
  };

  const { data: pageData, isLoading } = useQuery(['pageData', slug], fetchPageData);
  
  const { data: mediaData } = useQuery(['mediaData', pageData?.featured_media], () => fetchMedia(pageData?.featured_media), {
    enabled: !!pageData?.featured_media
  });

  const pageTitle = pageData?.title?.rendered;
  const pageContent = pageData ? adjustContentUrls(pageData.content.rendered) : null;
  const pageExcerpt = pageData ? stripHtml(pageData.excerpt.rendered) : null;
  const metaImage = mediaData?.media_details?.sizes?.large?.source_url || DEFAULT_IMAGE_URL;

  return (
    <PageTemplate>
      <Helmet>
        <title>{pageTitle ? `${pageTitle} - Gyvenu Kaune` : 'Gyvenu Kaune'}</title>
        <meta name="description" content={pageExcerpt}></meta>
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="lt_LT" />
        <meta property="og:image" content={metaImage} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageExcerpt} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      {isLoading && <div>Loading...</div>}

      {!isLoading && (
        <>
          <h1>{pageTitle}</h1>
          {mediaData && (
            <FeaturedMedia 
              imageUrl={mediaData.media_details.sizes.large.source_url} 
              altText={pageTitle}
              caption={mediaData.alt_text}
            />
          )}
          <div className="renderedContent" dangerouslySetInnerHTML={{ __html: pageContent }} />
        </>
      )}
    </PageTemplate>
  );
}

export default DynamicPage;
