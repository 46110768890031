import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, AUTH_HEADER } from '../../config';
import MainMenu from './MainMenuComponent';

function Header() {
    const [siteTitle, setSiteTitle] = useState('');

    useEffect(() => {
        fetch(`${API_URL}/settings`, {
          headers: {
              'Authorization': AUTH_HEADER
          }
        })
        .then(response => response.json())
        .then(data => {
          if (data.title) {
            setSiteTitle(data.title);  // Set the site title from the fetched data
            }
        });
      }, []);

    return (
        <header>
            <div className="headerWrapper">
              <Link className="branding" to={`/`} alt={siteTitle} title={siteTitle}>{siteTitle}</Link>
              <MainMenu />
            </div>
        </header>
    );
}

export default Header;