import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { API_URL, DEFAULT_IMAGE_URL } from '../config';
import { TailSpin } from 'react-loader-spinner';
import PageTemplate from './PageTemplate';
import PostComponent from '../components/post/PostComponent';
import PaginationComponent from '../components/global/PaginationComponent';
import { Helmet } from 'react-helmet';

function ArchivePage() {
    const { category } = useParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const pageParam = parseInt(query.get('page')) || 1;

    const [page, setPage] = useState(pageParam);

    const fetchCategory = async () => {
        const res = await fetch(`${API_URL}/categories?slug=${category}`);
        const data = await res.json();
        return data[0];
    };

    const fetchPostsByCategory = async (categoryId) => {
        const res = await fetch(`${API_URL}/posts?_embed&categories=${categoryId}&page=${page}`);
        const totalPages = Number(res.headers.get('X-WP-TotalPages'));
        const data = await res.json();
        return { data, totalPages };
    };

    const { data: categoryData, isLoading: isLoadingCategory } = useQuery(['category', category], fetchCategory);

    const { data: postData, isLoading: isLoadingPosts, isFetching } = useQuery(['posts', categoryData?.id, page], () => fetchPostsByCategory(categoryData?.id), {
        keepPreviousData: true,
        enabled: !!categoryData,
    });

    const posts = postData?.data;
    const totalPages = postData?.totalPages;

    useEffect(() => {
        setPage(pageParam);
    }, [pageParam]);

    return (
        <PageTemplate>
            <Helmet>
                <title>{categoryData?.name ? `${categoryData.name} - Gyvenu Kaune` : 'Gyvenu Kaune'}</title>
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="lt_LT" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={DEFAULT_IMAGE_URL} />
                <meta property="og:title" content={categoryData?.name} />
            </Helmet>

            <h2>Įrašai kategorijoje: {categoryData?.name}</h2>

            {isLoadingCategory || isLoadingPosts || isFetching ? (
                <TailSpin
                 height="50"
                 width="50"
                 ariaLabel="Kraunasi..."
                 color="black"
                />
            ) : (
                <>
                    {posts && posts.length === 0 && <div>Kategorijoje <strong>{categoryData?.name}</strong> įrašų nerasta.</div>}

                    <section className="posts-block">
                        {posts && posts.map(post => (
                            <PostComponent post={post} key={post.id} />
                        ))}
                    </section>

                    {totalPages > 1 && <PaginationComponent currentPage={page} totalPages={totalPages} />}
                </>
            )}
        </PageTemplate>
    );
}

export default ArchivePage;
